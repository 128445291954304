@use 'node_modules/@armis/armis-ui-library/dist/styles/index';

html {
    font-size: 62.5%;
    height: 100%;
}
body,
#root {
    height: 100%;
}
